import React from 'react';
import Airwaybill from './Airwaybill';
import { Box } from '@mui/material';
import ScreeningDetails from './SreeningDetails';
import AttachmentContainer from '../attachment/AttachmentContainer';
import moment from 'moment';
import http from 'utils/http';
import dayjs from 'dayjs';

class AirwaybillContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      modal: false,
      screeningModal: false,
      search: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        awb: null,
      },
      awbs: [],
      originalAwbs: [],
      awbId: 0,
      airline: [],
      flight: [],
      preScreen: [],
      measure:[],
      method:[],
      filter: {
        airline: null,
        flight: null,
        preScreen: null,
        measure: null,
        method: null,
      },
    };
  }

  componentDidMount() {
    this.fetchAwbs();
  }

  handlePopover = (modal, anchorEl, id) => {
    if (modal == 'screeningdetails') {
      this.setState({
        screeningModal: anchorEl,
        awbId: id
      });
    } else {
      this.setState({
        modal,
        anchorEl,
        awbId: id
      });
    }

  };

  fetchAwbs = (awb = false) => {
    let query = '';
    if (awb) {
      query = 'awb=' + this.state.search.awb;
    } else {
      query = `from=${dayjs(this.state.search.from).format('YYYY-MM-DD')}&to=${dayjs(this.state.search.to).format('YYYY-MM-DD')}&type=doc`;
    }
    http.get('/screening?' + query).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ 
          awbs: data.data, 
          originalAwbs: data.data 
        }, () => {
          this.getFilter();
          // this.clear();
        });
      }
    });
  };

  clear = () => {
    this.setState({
      filter: {
        airline: null,
        flight: null,
        preScreen: null,
        measure: null,
        method: null
      },
    }, () => {
      this.setState({ awbs: [...this.state.awbs] });
      this.getFilter();
      this.fetchAwbs(false);
    });
  };

  handleFilterChange = (event, type) => {
    let value = event.target ? event.target.value : event;
    // const name = event.target ? event.target.name : event;
    if (type === 'awb') {
      value = this.handleChangeAwb(value); // Call handleChangeAwb function
    }
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [type]: value
      }
    }));
    // if (type === 'from' || type === 'to') {
    //   this.fetchAwbs();
    // }
  };

  handleChangeAwb = (value) => {

    if (value.length === 3 && (this.state.search.awb?.length === 2)) {
      value = value + '-'; 
    }
    if (value.length === 11) {
      if (value.indexOf('-') < 0) {
        value = value.slice(0 , 3) + '-' + value.slice(3);
      }
    }
    if (value.split('-').length > 2) {
      return;
    }
    
    return value;
  };

  getFilter = () => {
    let airline = [];
    let flight = [];
    let preScreen = [];
    let measure = [];
    let method = [];
    this.state.awbs.map(a => {
      let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
      if (!flight.includes(f)) {
        flight.push(f);
      }
      if (a.airline) {
        if (!airline.includes(a.airline.code)) {
          airline.push(a.airline.code);
        }
      }
      if (a.pre_screened) {
        if (!preScreen.includes(a.pre_screened)) {
          preScreen.push(a.pre_screened);
        }
      }
      if (a.measure) {
        if (!measure.type.includes(a.measure)) {
          measure.type.push(a.measure);
        }
      }
      if (a.method) {
        if (!method.includes(a.method.name)) {
          method.push(a.method.name);
        }
      }
    });
    this.setState({
      airline,
      flight,
      preScreen,
      measure,
      method
    });
  };
  
  filterData = () => {
    let awbs = [...this.state.originalAwbs];
    if (this.state.filter.airline && this.state.filter.airline != '') {
      awbs = awbs.filter((a) => a.airline.code === this.state.filter.airline);
    }
    if (this.state.filter.flight && this.state.filter.flight != '') {
      awbs = awbs.filter((a) => {
        let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
        return f === this.state.filter.flight;
      });
    }
    if (this.state.filter.preScreen && this.state.filter.preScreen != '') {
      awbs = awbs.filter((a) => a.pre_screened === this.state.filter.preScreen);
    }
    if (this.state.filter.measure && this.state.filter.measure != '') {
      awbs = awbs.filter((a) => a.measure.type === this.state.filter.measure);
    }
    if (this.state.filter.method && this.state.filter.method != '') {
      awbs = awbs.filter((a) => a.method.name === this.state.filter.method);
    }
    this.setState({ awbs });
  };

  onChangefilter = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value
      }
    }, () => { this.filterData(); });
  };

  handlePopoverClose = (modal) => {
    if (modal == 'screeningdetails') {
      this.setState({ screeningModal: false });
    } else {
      this.setState({
        attachment: false,
        anchorEl: false
      });
    }

  };

  render() {
    return (
      <Box sx={{ position: 'relative' }}>
        <Airwaybill
          handlePopover={this.handlePopover}
          handlePopoverClose={this.handlePopoverClose}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          awbs={this.state.awbs}
          searchOptions={this.state.search}
          handleChange={this.handleFilterChange}
          search={this.fetchAwbs}
          clear={this.clear}
          airline={this.state.airline}
          flight={this.state.flight}
          preScreen={this.state.preScreen}
          measure={this.state.measure}
          method={this.state.method}
          onChangefilter={this.onChangefilter}
          filter={this.state.filter}
        />
        {this.state.screeningModal && <ScreeningDetails
          handlePopover={this.handlePopover}
          anchorEl={this.anchorEl}
          modal={this.state.modal}
          id={this.state.awbId}
        />}
        {this.state.modal === 'attached_docs' &&
          <AttachmentContainer
            handlePopover={this.handlePopover}
            anchorEl={this.anchorEl}
            modal={this.state.modal}
            type={'AWB'}
            id={this.state.awbId}
          />
        }

      </Box>
    );
  }
}

export default AirwaybillContainer;
